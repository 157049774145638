import React from 'react'
import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import wortmann_ag_img from '../icons/wortmann-ag-logo.svg'
import tui_img from '../icons/logo-tui.svg'
import microsoft_img from '../icons/microsoft-logo.svg'
import leftArrow from '../icons/nav-arrow-left.svg'
import rightArrow from '../icons/nav-arrow-right.svg'
import terra_mobile_img from '../icons/terra-mobile.svg'
import terra_premium_img from '../icons/terra-premium.svg'
import terra_value_img from '../icons/terra-value.svg'
const slideImages = [
   wortmann_ag_img,
   microsoft_img,
   tui_img,
   terra_premium_img,
   terra_value_img,
   terra_mobile_img,
]
const imageLinks = [
   'https://www.wortmann.de/',
   'https://www.microsoft.com/',
   'https://www.tu-ilmenau.de/',
   'https://www.wortmann.de/',
   'https://www.wortmann.de/',
   'https://www.wortmann.de/',
]

const properties = {
   duration: 5000,
   transitionDuration: 700,
   infinite: true,
   prevArrow: (
      <div style={{ width: '30px', marginRight: '-30px', cursor: 'pointer' }}>
         <img src={leftArrow} alt="left_arrow" />
      </div>
   ),
   nextArrow: (
      <div style={{ width: '30px', marginLeft: '-30px', cursor: 'pointer' }}>
         <img src={rightArrow} alt="right_arrow" />
      </div>
   ),
}

const Slideshow = () => {
   return (
      <div
         className="slide-container"
         style={{
            width: '100%',
            height: '700px',
            paddingLeft: '5%',
            paddingRight: '5%',
            textAlign: 'center',
            marginTop: "-50px",
            marginBottom: "-50px"
         }}
      >
         <Slide
            {...properties}
            style={{
               textAlign: 'center',
            }}
         >
            {slideImages.map((each, index) => (
               <div
                  key={index}
                  className="each-slide"
                  style={{
                     textAlign: 'center',
                  }}
               >
                  <a
                     href={imageLinks[index]}
                     target="_blank"
                     rel="noopener noreferrer"
                     style={{
                        textAlign: 'center',
                        
                     }}
                  >
                    
                     <img
                        className="lazy"
                        src={each}
                        alt="sample"
                        href=""
                        style={{
                           textAlign: 'center',
                           height: '90%',
                           width: '90%',
                        }}
                     />
                  </a>
               </div>
            ))}
         </Slide>
      </div>
   )
}

export default Slideshow
