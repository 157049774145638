import React, { Component } from 'react'
import { rgb } from 'color'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Popover from '@material-ui/core/Popover'
import { createTheme } from '@material-ui/core/styles'
import logo from '../icons/Logo.png'
import circuit_board from '../icons/circuit-board.svg'
import { FaBars } from 'react-icons/fa'
import { ImCross } from 'react-icons/im'
import { NavLink as Link } from 'react-router-dom'
import styled from 'styled-components'
import DownloadSelect from './DownloadSelect'
import DownloadSelectNavBar from './DownloadSelectNavBar'

const Nav = styled.nav`
   background: #000;
   height: 80px;
   display: flex;
   justify-content: space-between;
   padding: 0.5rem calc((100vw - 900px) / 2);
   z-index: 10;

   /* Third Nav */
   /* justify-content: flex-start; */
`

const NavLink = styled(Link)`
   color: #e4ebfd;
   display: flex;
   align-items: center;
   text-decoration: none;
   padding: 0 1rem;
   height: 100%;
   cursor: pointer;

   &.active {
      color: #15cdfc;
   }
`

const Cross = styled(ImCross)`
   display: none;
   color: #e4ebfd;

   @media screen and (max-width: 1080px) {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-35%, 80%);
      font-size: 1.8rem;
      cursor: pointer;
   }
`

const Bars = styled(FaBars)`
   display: none;
   color: #e4ebfd;

   @media screen and (max-width: 1080px) {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-35%, 80%);
      font-size: 1.8rem;
      cursor: pointer;
   }
`

const NavMenu = styled.div`
   display: flex;
   align-items: center;
   margin-right: -24px;

   /* Second Nav */
   /* margin-right: 24px; */

   /* Third Nav */
   /* width: 100vw;
  white-space: nowrap; */

   @media screen and (max-width: 1080px) {
      display: none;
   }
`
const navBarLinks = {
   about: { label: 'Über uns', dir: 'lrt', active: false, index: 0 },
   partner: { label: 'Partner', dir: 'lrt', active: false, index: 1 },

   itInfrastructure: {
      label: 'IT-Infrastruktur',
      dir: 'lrt',
      active: false,
      index: 2,
   },
   Impressum: { label: 'Impressum', dir: 'lrt', active: false, index: 3 },
}

const theme = createTheme({
   typography: {
      fontFamily: [
         '-apple-system',
         'BlinkMacSystemFont',
         '"Segoe UI"',
         'Roboto',
         '"Helvetica Neue"',
         'Arial',
         'sans-serif',
         '"Apple Color Emoji"',
         '"Segoe UI Emoji"',
         '"Segoe UI Symbol"',
      ].join(','),
   },
})

const Navbar = (props) => {
   const { changeSelection } = props
   const [showStateSupport, setShowStateSupport] = React.useState(true)
   const [showState, setShowState] = React.useState(true)
   const toggleVisibility = () => {
      if (showState) {
         setShowState(false)
      } else {
         setShowState(true)
      }
   }

   return (
      <div>
         <Nav
            style={{
               backgroundColor: rgb(25, 70, 136),
               backgroundImage: `url(${circuit_board})`,
               backgroundRepeat: 'no-repeat',
               backgroundSize: 'cover',
            }}
         >
            <NavLink onClick={() => changeSelection(0)} to="">
               <img src={logo} alt="logo" className="mainLogo" />
            </NavLink>
            <div class="bars-container">
               <div>
                  {showState && (
                     <Bars
                        id="0"
                        style={{
                           width: '50px',
                        }}
                        onClick={toggleVisibility}
                     />
                  )}
               </div>
               <div>
                  {!showState && (
                     <Cross
                        id="1"
                        style={{
                           width: '50px',
                        }}
                        onClick={toggleVisibility}
                     />
                  )}
               </div>
            </div>
            <Popover
               class="popover-menu"
               open={!showState}
               anchorEl={showState}
               onClose={toggleVisibility}
               anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
               }}
               transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
               }}
            >
               <div>
                  <List theme={theme}>
                     {Object.keys(navBarLinks)?.map((item) => (
                        <ListItem
                           button
                           key={item}
                           onClick={() => {
                              changeSelection(navBarLinks[item].index) //relative to domain
                              toggleVisibility()
                           }}
                        >
                           {navBarLinks[item].label}
                        </ListItem>
                     ))}
                     <ListItem button>
                        <DownloadSelect />
                     </ListItem>
                  </List>
               </div>
            </Popover>
            <NavMenu>
               <button class="navbar-button" onClick={() => changeSelection(0)}>
                  Über uns
               </button>
               <button class="navbar-button" onClick={() => changeSelection(1)}>
                  Partner
               </button>
               <button class="navbar-button" onClick={() => changeSelection(2)}>
                  It-Infrastruktur
               </button>
               <DownloadSelectNavBar />
               <button class="navbar-button" onClick={() => changeSelection(3)}>
                  Impressum
               </button>
            </NavMenu>
         </Nav>
      </div>
   )
}

export default Navbar
