import React from 'react'

function ItInfrastructure() {
   return (
      <div className="it_infrastructure">
         <div class="container" style={{ marginBottom: '50px' }}>
            <div class="row align-items-center my-5">
               <div class="col-lg-12">
                  <h2 class="font-weight-light">IT-Infrastruktur</h2>
                  <p>
                     Eine durchdachte und an den konkreten Anforderungen
                     orientierte EDV ist das Rückgrat eines jeden Unternehmens
                     und die Basis weiteren Geschäftserfolges. Da sollte man
                     nichts dem Zufall überlassen. Fragen Sie deshalb den
                     Fachmann, um für Heute und Morgen gerüstet zu sein. Unsere
                     kompetenten Mitarbeiter stehen für Sie bei :
                  </p>
                  <ul>
                     <li>Aufbau und der Erweiterung Ihrer IT-Infrastruktur</li>
                     <li>Einrichtung von PC-Systemen</li>
                     <li>Installation und Auswahl von Software und Zubehör</li>
                     <li>Softwareentwicklung und -anpassung</li>
                     <li>Vernetzung und der Installation von Servern,</li>
                     <li>Internetzugangslösungen</li>
                     <li>
                        VPN-Netzwerk-Kopplungen und dem Anfertigung von
                        Messprotokollen zur Verfügung
                     </li>
                  </ul>
                  <p>
                     Wir verwenden verlässliche und geprüfte Komponenten und Softwareprodukte. Eine hohe
                     Zuverlässigkeit und die Kompatibilität des Gesamtsystems
                     werden dadurch garantiert. Unsere IT-Produkte beziehen wir
                     von einer Vielzahl namhafter Hersteller.
                  </p>
               </div>
            </div>
         </div>
      </div>
   )
}

export default ItInfrastructure
