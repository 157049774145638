import React from 'react'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import Popover from '@material-ui/core/Popover'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import { createMuiTheme } from '@material-ui/core/styles'
import Spacer from 'react-spacer'

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
})

const languageMap = {
  qs: { label: 'TeamViewer Download', dir: 'lrt', active: true, function: "qs"},
  pc: { label: 'PC Visit Download', dir: 'ltr', active: false, function: "pc"},
}

var downloadPcVisit = () => {
       window.open("https://www.pcvisit.de/kundenbereich/downloads/", '_blank').focus();
   }

   var downloadQs = () => {
      window.open("https://get.teamviewer.com/7n7zrq7", '_blank').focus();
   }


const LanguageSelect = () => {
  const selected = "qs"

  const [menuAnchor, setMenuAnchor] = React.useState(null)
  React.useEffect(() => {
    document.body.dir = languageMap[selected].dir
  }, [menuAnchor, selected])

  return (
    <div className="d-flex justify-content-end align-items-center language-select-root">
      <button
        class="popover-list-button"
        onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}
      >Support<ArrowDropDown />
      </button>
      
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div>
          <List theme={theme}>
            <ListSubheader theme={theme}>Support</ListSubheader>
            {Object.keys(languageMap).map((item) => (
              <ListItem
                button
                key={item}
                onClick={() => {
                  if(languageMap[item].function === "qs"){
                    downloadQs()
                    setMenuAnchor(null)
                  } else {
                      downloadPcVisit()
                      setMenuAnchor(null)
                  }
                  
                }}
              >
                <img src={languageMap[item].img} alt="" />
                <Spacer width="5px" />
                {languageMap[item].label}
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </div>
  )
}

export default LanguageSelect
