import Carousel from 'react-multi-carousel'
import './style.css'
import sys_admin from '../icons/sys-admin.jpg'
import network from '../icons/network.jpg'
import approved from '../icons/approved.jpg'
import leftArrow from '../icons/nav-arrow-left.svg'
import rightArrow from '../icons/nav-arrow-right.svg'
import Spacer from 'react-spacer'
import { rgb } from 'color'
const responsive = {
   superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
   },
   desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
   },
   tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
   },
   mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
   },
}
const arrowStyle = {
   background: 'transparent',
   border: 0,
   color: rgb(144, 174, 252),
   fontSize: '80px',
}

const CustomRight = ({ onClick }) => (
   <button className="arrow right" onClick={onClick} style={arrowStyle}>
      <img
         style={{ color: rgb(144, 174, 252) }}
         src={rightArrow}
         alt="left_arrow"
      />
   </button>
)
const CustomLeft = ({ onClick }) => (
   <button className="arrow left" onClick={onClick} style={arrowStyle}>
      <img
         style={{ color: rgb(144, 174, 252) }}
         src={leftArrow}
         alt="right_arrow"
      />
   </button>
)

function CardSlider() {
   return (
      <Carousel
         responsive={responsive}
         customRightArrow={<CustomRight />}
         customLeftArrow={<CustomLeft />}
         showDots
      >
         <div style={{ textAlign: 'center' }}>
            <div class="each-card">
               <div class="each-inner-card">
                  <h2
                     class="font-weight-light"
                     style={{ color: rgb(144, 174, 252) }}
                  >
                     IT-Infrastruktur
                  </h2>
                  <a
                     href="http://www.freepik.com/vectorjuice"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     <img
                        class="sys-admin-img"
                        src={sys_admin}
                        alt="sys_admin"
                     />
                  </a>
                  <Spacer height="35px" />
                  <p style={{ textAlign: 'left' }}>
                     Eine durchdachte und an den konkreten Anforderungen
                     orientierte EDV ist das Rückgrat eines jeden Unternehmens
                     und die Basis weiteren Geschäftserfolges. Da sollte man
                     nichts dem Zufall überlassen. Fragen Sie deshalb den
                     Fachmann, um für Heute und Morgen gerüstet zu sein.
                  </p>
               </div>
               <Spacer height="60px" />
            </div>
         </div>
         <div style={{ textAlign: 'center' }}>
            <div class="each-card">
               <div class="each-inner-card">
                  <h2
                     class="font-weight-light"
                     style={{
                        color: rgb(144, 174, 252),
                        margin: 0,
                        padding: 0,
                     }}
                  >
                     Unsere Services
                  </h2>
                  <Spacer height="20px" />
                  <p style={{ textAlign: 'left', margin: 0, padding: 0 }}>
                     Aufbau und der Erweiterung Ihrer IT-Infrastruktur
                  </p>
                  <p style={{ textAlign: 'left', margin: 0, padding: 0 }}>
                     Einrichtung von PC-Systemen
                  </p>
                  <p style={{ textAlign: 'left', margin: 0, padding: 0 }}>
                     Installation und Auswahl von Software und Zubehör
                  </p>
                  <p style={{ textAlign: 'left', margin: 0, padding: 0 }}>
                     Softwareentwicklung und -anpassung
                  </p>
                  <p style={{ textAlign: 'left', margin: 0, padding: 0 }}>
                     Vernetzung und der Installation von Servern,
                  </p>
                  <p style={{ textAlign: 'left', margin: 0, padding: 0 }}>
                     Internetzugangslösungen
                  </p>
                  <p style={{ textAlign: 'left', margin: 0, padding: 0 }}>
                     VPN - Netzwerk-Kopplungen und dem Anfertigung von
                     Messprotokollen zur Verfügung
                  </p>
                  <a
                     href="http://www.freepik.com/vectorjuice"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     <img class="offering-img" src={network} alt="sys_admin" />
                  </a>
               </div>
            </div>
         </div>
         <div style={{ textAlign: 'center' }}>
            <div class="each-card">
               <div class="each-inner-card">
                  <h2
                     class="font-weight-light"
                     style={{ color: rgb(144, 174, 252) }}
                  >
                     Unser Versprechen
                  </h2>
                  <p style={{ textAlign: 'left' }}>
                     Wir verwenden ausschließlich intern oder verlässlich extern
                     geprüfte Komponenten und Softwareprodukte. Eine hohe
                     Zuverlässigkeit und die Kompatibilität des Gesamtsystems
                     werden dadurch garantiert.
                  </p>
                  <a
                     href="http://www.freepik.com/vectorjuice"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     <img
                        class="approved-img"
                        src={approved}
                        alt="approved_img"
                     ></img>
                  </a>
                  <p style={{ textAlign: 'left' }}>
                     {' '}
                     Durch Verwendung jeweils aktuellster Technologien rüsten
                     wir Sie für die Zukunft. Gleichzeitig sorgt die Ausrüstung
                     mit allen Schnittstellen für Flexibilität bei sich
                     ändernden Anforderungen wenn Ihr Unternehmen wächst.
                  </p>
                  <p style={{ textAlign: 'left' }}>
                     Unsere IT-Produkte beziehen wir von einer Vielzahl
                     namenhafter Hersteller.
                  </p>
               </div>
            </div>
         </div>
      </Carousel>
   )
}
export default CardSlider
