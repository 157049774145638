import React, { useState } from 'react'
import Spacer from 'react-spacer'
import { useCookies } from 'react-cookie'
import { rgb } from 'color'
import Button from '@material-ui/core/Button'
import teamviewer_logo from '../icons/teamviewer-logo.png'
import pc_visit_logo from '../icons/pc-visit-logo.png'

const cookieString = 'ce_sys_cookie'

export default function StickyFooter() {
   const [state, setState] = useState(0)
   const [cookies, setCookie] = useCookies([cookieString])

   var isMobile = window.orientation > -1

   var showModal = () => {
      setState({ show: true })
   }

   var hideModal = () => {
      setState({ show: false })
   }

   var hideModalAndAccept = () => {
      setCookie(cookieString, 'true', {
         path: '/',
      })
      setState({ show: false })
   }

   var downloadPcVisit = () => {
      window
         .open('https://www.pcvisit.de/kundenbereich/downloads/', '_blank')
         .focus()
   }

   var downloadQs = () => {
      window.open('https://get.teamviewer.com/7n7zrq7', '_blank').focus()
   }

   return (
      <div className="sticky-footer">
         <footer
            class="py-2 fixed-bottom"
            style={{ backgroundColor: rgb(228, 235, 253) }}
         >
            <div class="container-footer">
               <a class="ToggleButton" href={showModal}>
                  <div className="text-center">
                     {!cookies.ce_sys_cookie && (
                        <p>
                           Diese Website benutzt&nbsp;
                           <Button
                              class="footer-link-button"
                              onClick={showModal}
                           >
                              Cookies&nbsp;
                           </Button>
                           um Ihre Nutzererfahrung zu verbessern und hat eine
                           erneuerte&nbsp;
                           <Button
                              class="footer-link-button"
                              onClick={showModal}
                           >
                              Datenschutzerklärung
                           </Button>
                           <Button
                              class="footer-button"
                              onClick={hideModalAndAccept}
                           >
                              Akzeptieren
                           </Button>
                        </p>
                     )}
                     {cookies.ce_sys_cookie && (
                        <>
                           <Button
                              class="footer-button-text"
                              href="mailto:info@ce-sys-computer.de"
                           >
                              <p style={{ color: '#000000' }}>
                                 info@ce-sys-computer.de
                              </p>
                              <span class="tooltiptext">e-mail schreiben</span>
                           </Button>
                           {!isMobile && (
                              <>
                                 <Button
                                    class="download-button"
                                    onClick={downloadQs}
                                 >
                                    <div>
                                       <img
                                          className="teamviewer-logo"
                                          src={teamviewer_logo}
                                          alt="teamviewer_logo"
                                       />
                                       <span class="tooltiptext">
                                          download quicksupport
                                       </span>
                                    </div>
                                 </Button>
                                 <Button
                                    class="download-button"
                                    onClick={downloadPcVisit}
                                 >
                                    <div>
                                       <img
                                          class="pc-visit-logo"
                                          src={pc_visit_logo}
                                          alt="pc_visit_logo"
                                       />
                                       <span class="tooltiptext">
                                          download pc-visit
                                       </span>
                                    </div>
                                 </Button>
                              </>
                           )}
                           <Button
                              class="footer-button-text"
                              href="tel:+493677/64790"
                           >
                              <p style={{ color: '#000000' }}>+49 3677/64790</p>
                              <span class="tooltiptext">ce-sys anrufen</span>
                           </Button>
                        </>
                     )}
                  </div>
                  <Modal
                     show={state.show}
                     handleAccept={hideModalAndAccept}
                     handleClose={hideModal}
                  >
                     <p>
                        <strong>
                           <big>Datenschutzerklärung </big>
                        </strong>
                     </p>
                     <p>
                        <strong>
                           Allgemeiner Hinweis und Pflichtinformationen
                        </strong>
                     </p>
                     <p>
                        <strong>Benennung der verantwortlichen Stelle</strong>
                     </p>
                     <p>
                        Die verantwortliche Stelle für die Datenverarbeitung auf
                        dieser Website ist:
                     </p>
                     <ul>
                        <li>
                           <span id="s3-t-firma">
                              CE-SYS GmbH Ilmenau Computer & Elektronik
                              Systemhaus
                           </span>
                        </li>
                        <li>
                           <span id="s3-t-ansprechpartner">
                              Dr.-Ing. Ralph Mikolaschek
                           </span>
                        </li>
                        <li>
                           <span id="s3-t-ansprechpartner">
                              Dr.-Ing. Udo Völker{' '}
                           </span>
                        </li>
                        <li>
                           <span id="s3-t-strasse">Am Hammergrund 1</span>
                        </li>
                        <li>
                           <span id="s3-t-plz">98693</span>
                        </li>
                        <li>
                           <span id="s3-t-ort">Ilmenau</span>
                        </li>
                     </ul>
                     <p>
                        Die verantwortliche Stelle entscheidet allein oder
                        gemeinsam mit anderen über die Zwecke und Mittel der
                        Verarbeitung von personenbezogenen Daten (z.B. Namen,
                        Kontaktdaten o. Ä.).
                     </p>

                     <p>
                        <strong>
                           Widerruf Ihrer Einwilligung zur Datenverarbeitung
                        </strong>
                     </p>
                     <p>
                        Nur mit Ihrer ausdrücklichen Einwilligung sind einige
                        Vorgänge der Datenverarbeitung möglich. Ein Widerruf
                        Ihrer bereits erteilten Einwilligung ist jederzeit
                        möglich. Für den Widerruf genügt eine formlose
                        Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum
                        Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
                        unberührt.
                     </p>

                     <p>
                        <strong>
                           Recht auf Beschwerde bei der zuständigen
                           Aufsichtsbehörde
                        </strong>
                     </p>
                     <p>
                        Als Betroffener steht Ihnen im Falle eines
                        datenschutzrechtlichen Verstoßes ein Beschwerderecht bei
                        der zuständigen Aufsichtsbehörde zu. Zuständige
                        Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen
                        ist der Landesdatenschutzbeauftragte des Bundeslandes,
                        in dem sich der Sitz unseres Unternehmens befindet. Der
                        folgende Link stellt eine Liste der
                        Datenschutzbeauftragten sowie deren Kontaktdaten
                        bereit:&nbsp;
                        <a
                           href="https://www.bfdi.bund.de/DE/Service/Anschriften/anschriften_node.html"
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                           https://www.bfdi.bund.de/DE/Service/Anschriften/anschriften_node.html
                        </a>
                        .
                     </p>

                     <p>
                        <strong>Recht auf Datenübertragbarkeit</strong>
                     </p>
                     <p>
                        Ihnen steht das Recht zu, Daten, die wir auf Grundlage
                        Ihrer Einwilligung oder in Erfüllung eines Vertrags
                        automatisiert verarbeiten, an sich oder an Dritte
                        aushändigen zu lassen. Die Bereitstellung erfolgt in
                        einem maschinenlesbaren Format. Sofern Sie die direkte
                        Übertragung der Daten an einen anderen Verantwortlichen
                        verlangen, erfolgt dies nur, soweit es technisch machbar
                        ist.
                     </p>

                     <p>
                        <strong>
                           Recht auf Auskunft, Berichtigung, Sperrung, Löschung
                        </strong>
                     </p>
                     <p>
                        Sie haben jederzeit im Rahmen der geltenden gesetzlichen
                        Bestimmungen das Recht auf unentgeltliche Auskunft über
                        Ihre gespeicherten personenbezogenen Daten, Herkunft der
                        Daten, deren Empfänger und den Zweck der
                        Datenverarbeitung und ggf. ein Recht auf Berichtigung,
                        Sperrung oder Löschung dieser Daten. Diesbezüglich und
                        auch zu weiteren Fragen zum Thema personenbezogene Daten
                        können Sie sich jederzeit über die im Impressum
                        aufgeführten Kontaktmöglichkeiten an uns wenden.
                     </p>

                     <p>
                        <strong>SSL- bzw. TLS-Verschlüsselung</strong>
                     </p>
                     <p>
                        Aus Sicherheitsgründen und zum Schutz der Übertragung
                        vertraulicher Inhalte, die Sie an uns als
                        Seitenbetreiber senden, nutzt unsere Website eine
                        SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie
                        über diese Website übermitteln, für Dritte nicht
                        mitlesbar. Sie erkennen eine verschlüsselte Verbindung
                        an der „https://“ Adresszeile Ihres Browsers und am
                        Schloss-Symbol in der Browserzeile.
                     </p>

                     <p>
                        <strong>Server-Log-Dateien</strong>
                     </p>
                     <p>
                        In Server-Log-Dateien erhebt und speichert der Provider
                        der Website automatisch Informationen, die Ihr Browser
                        automatisch an uns übermittelt. Dies sind:
                     </p>
                     <ul>
                        <li>Besuchte Seite auf unserer Domain</li>
                        <li>Datum und Uhrzeit der Serveranfrage</li>
                        <li>Browsertyp und Browserversion</li>
                        <li>Verwendetes Betriebssystem</li>
                        <li>Referrer URL</li>
                        <li>Hostname des zugreifenden Rechners</li>
                        <li>IP-Adresse</li>
                     </ul>
                     <p>
                        Es findet keine Zusammenführung dieser Daten mit anderen
                        Datenquellen statt. Grundlage der Datenverarbeitung
                        bildet Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung
                        von Daten zur Erfüllung eines Vertrags oder
                        vorvertraglicher Maßnahmen gestattet.
                     </p>

                     <p>
                        <strong>Cookies</strong>
                     </p>

                     <p>
                        Unsere Website verwendet Cookies. Das sind kleine
                        Textdateien, die Ihr Webbrowser auf Ihrem Endgerät
                        speichert. Cookies helfen uns dabei, unser Angebot
                        nutzerfreundlicher, effektiver und sicherer zu machen.{' '}
                     </p>
                     <p>
                        Einige Cookies sind “Session-Cookies.” Solche Cookies
                        werden nach Ende Ihrer Browser-Sitzung von selbst
                        gelöscht. Hingegen bleiben andere Cookies auf Ihrem
                        Endgerät bestehen, bis Sie diese selbst löschen. Solche
                        Cookies helfen uns, Sie bei Rückkehr auf unserer Website
                        wiederzuerkennen.
                     </p>
                     <p>
                        Mit einem modernen Webbrowser können Sie das Setzen von
                        Cookies überwachen, einschränken oder unterbinden. Viele
                        Webbrowser lassen sich so konfigurieren, dass Cookies
                        mit dem Schließen des Programms von selbst gelöscht
                        werden. Die Deaktivierung von Cookies kann eine
                        eingeschränkte Funktionalität unserer Website zur Folge
                        haben.
                     </p>
                     <p>
                        Das Setzen von Cookies, die zur Ausübung elektronischer
                        Kommunikationsvorgänge oder der Bereitstellung
                        bestimmter, von Ihnen erwünschter Funktionen (z.B.
                        Warenkorb) notwendig sind, erfolgt auf Grundlage von
                        Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website
                        haben wir ein berechtigtes Interesse an der Speicherung
                        von Cookies zur technisch fehlerfreien und reibungslosen
                        Bereitstellung unserer Dienste. Sofern die Setzung
                        anderer Cookies (z.B. für Analyse-Funktionen) erfolgt,
                        werden diese in dieser Datenschutzerklärung separat
                        behandelt.
                     </p>
                  </Modal>
               </a>
            </div>
            <iFrame id="download_iframe" style={{ display: 'none' }} />
         </footer>
      </div>
   )
}

const Modal = ({ handleAccept, handleClose, show, children }) => {
   const showHideClassName = show ? 'modal display-block' : 'modal display-none'
   return (
      <div className={showHideClassName}>
         <section className="modal-main">
            <Spacer height="20px" />
            {children}

            <Button class="footer-button" onClick={handleAccept}>
               Akzeptieren
            </Button>

            <Button class="footer-button" onClick={handleClose}>
               Schließen
            </Button>
         </section>
      </div>
   )
}
