import React from 'react'
import ReactDOM from 'react-dom'
import {
   StickyFooter,
   Footer,
   Partner,
   About,
   NavBar,
   ItInfrastructure,
} from './components'
import { BrowserRouter as Router } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import * as serviceWorker from './serviceWorker'
import './style.css'

class App extends React.Component {
   constructor (props) {
      super(props)
      this.state = {
         selected: 0,
         theposition: null,
      }
   }
   //refs
   about = React.createRef()
   partner = React.createRef()
   it_infrastructure = React.createRef()
   imprint = React.createRef()

   changeSelection = (index) => {
      this.setState({
         selected: index,
      })
      this.markCurrentSelection(index)
   }

   componentDidMount() {
      window.addEventListener('scroll', this.listenToScroll)
      this.markCurrentSelection(this.state.selected)
   }

   componentWillUnmount() {
      window.removeEventListener('scroll', this.listenToScroll)
   }

   listenToScroll = () => {
      const winScroll =
         document.body.scrollTop || document.documentElement.scrollTop

      const height =
         document.documentElement.scrollHeight -
         document.documentElement.clientHeight

      const scrolled = (winScroll / height) * 1
      console.log(scrolled) //log current position

      if (scrolled >= 0.02 && scrolled <= 0.25) {
         this.markCurrentSelection(0)
      }
      if (scrolled >= 0.25 && scrolled <= 0.5) {
         this.markCurrentSelection(1)
      }
      if (scrolled >= 0.5 && scrolled <= 0.95) {
         this.markCurrentSelection(2)
      }
      if (scrolled >= 0.95 && scrolled <= 1) {
         this.markCurrentSelection(3)
      }
   }

   componentDidUpdate(prevProps, prevState) {
      this.scrollToSection(this.state.selected)
   }

   scrollToSection = (index) => {
      let refs = [
         this.about,
         this.partner,
         this.it_infrastructure,
         this.imprint,
      ]

      if (refs[index].current) {
         refs[index].current.scrollIntoView({
            behavior: 'smooth',
         })
      }
      this.markCurrentSelection(index)
   }

   markCurrentSelection = (index) => {
      var elements = document.querySelectorAll('li[data-nav]'),
         array = [],
         i

      for (i = 0; i < elements.length; i += 1) {
         array.push(i)
      }

      for (i in array) {
         if (document.getElementById(array[i]).classList.contains('current')) {
            document.getElementById(array[i]).classList.remove('current')
         }
      }
      document.getElementById(array[index]).classList.add('current')
   }

   render() {
      return (
         <div class="App">
            <ul class="nav__dot">
               <li
                  id="0"
                  class=""
                  onClick={() => this.changeSelection(0)}
                  data-nav="data-nav"
               ></li>
               <li
                  id="1"
                  class=""
                  onClick={() => this.changeSelection(1)}
                  data-nav="data-nav"
               ></li>
               <li
                  id="2"
                  class=""
                  onClick={() => this.changeSelection(2)}
                  data-nav="data-nav"
               ></li>
               <li
                  id="3"
                  class=""
                  onClick={() => this.changeSelection(3)}
                  data-nav="data-nav"
               ></li>
            </ul>
            <Router>
               <NavBar changeSelection={this.changeSelection} />
            </Router>

            <div class="col-lg-12" ref={this.about}>
               <div class="box">
                  <About />
               </div>
               <div class="box"></div>
            </div>
            <div class="col-lg-12" ref={this.partner}>
               <div class="box">
                  <Partner />
               </div>
            </div>
            <div class="col-lg-12" ref={this.it_infrastructure}>
               <div class="box">
                  <ItInfrastructure class="it-infrastructure" />
               </div>
            </div>
            <div class="col-lg-12" ref={this.imprint}>
               <Footer />
            </div>

            <StickyFooter />
         </div>
      )
   }
}

//ReactDOM.render(<App />, document.getElementById("root"));
const rootElement = document.getElementById('root')
ReactDOM.render(
   <CookiesProvider>
      <App class="App" />
   </CookiesProvider>,
   rootElement
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
