import { rgb } from 'color'
import React from 'react'
import Spacer from 'react-spacer'
import moment from 'moment'
import circuit_board from '../icons/circuit-board.svg'

var thisYear = moment().year()

function Footer() {
   return (
      <div   
      className="footer"
      style={{
         backgroundImage: `url(${circuit_board})`,
      }}>
         <footer
         >
            <div class="footer-container">
               <div class="row my-5">
                  <div class="col-lg-6">
                     <h3 class="font-weight-light">Impressum</h3>
                     <ul>
                        <li>
                           CE-SYS GmbH Computer & Elektronik Systemhaus
                        </li>
                        <a
                           style={{ color: 'white' }}
                           href="https://www.google.com/maps?ll=50.681468,10.89007&z=15&t=m&hl=en&gl=DE&mapclient=embed&q=Am+Hammergrund+1+98693+Ilmenau"
                        >
                           <li>Am Hammergrund 1</li>
                           <li>98693 Ilmenau</li>
                        </a>
                     </ul>
                     <h4 class="font-weight-light">Geschäftsführer:</h4>
                     <ul>
                        <li>Dr.-Ing. Ralph Mikolaschek</li>
                        <li>Dr.-Ing. Udo Völker</li>
                     </ul>
                     <ul>
                        <li>
                           Tel.:{' '}
                           <a
                              href="tel:49 (0) 3677 6479-0"
                              style={{ color: rgb(255, 255, 255) }}
                           >
                              +49 (0) 3677 6479-0
                           </a>
                        </li>
                        <li>
                           Fax:{' '}
                           <a
                              href="tel:+49 (0) 3677 6479-99"
                              style={{ color: rgb(255, 255, 255) }}
                           >
                              +49 (0) 3677 6479-99
                           </a>
                        </li>
                        <li>
                           E-Mail:{' '}
                           <a
                              href="mailto:info@ce-sys-computer.de"
                              style={{ color: rgb(255, 255, 255) }}
                           >
                              info@ce-sys-computer.de
                           </a>
                        </li>
                        <li>
                           URL:{' '}
                           <a
                              href="http://www.ce-sys-ilmenau.de/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: rgb(255, 255, 255) }}
                           >
                              www.ce-sys-ilmenau.de
                           </a>
                        </li>
                        <li>Handelsregister Jena</li>
                        <li>HRB 302966</li>
                     </ul>
                  </div>
                  <div class="col-lg-6">
                     <h5 class="font-weight-light">
                        USt.-IdNr. gemäss § 27 a Umsatzsteuergesetz
                     </h5>
                     <ul>
                        <p>DE151291937</p>
                     </ul>
                     <h5 class="font-weight-light">Urheberrechte</h5>
                     <ul>
                        <p>
                           Der Urheber dieser Seiten ist die CE-SYS GmbH
                           Computer & Elektronik Systemhaus. Die Reproduktion 
                           von Daten, Inhalten und Elementen dieser Seiten ist
                           ohne vorher eingeholte, schriftliche Genehmigung der
                           CE-SYS GmbH Computer & Elektronik Systemhaus
                           ausdrücklich untersagt. Einige Inhalte werden dem
                           Nutzer kostenlos zur Verfügung gestellt. Dieses
                           Material unterliegt den beigefügten
                           Nutzungsbedingungen.
                        </p>
                        <p>&copy; CE-SYS GmbH {thisYear}</p>
                     </ul>
                     <Spacer height="60px" />
                  </div>
               </div>
            </div>
         </footer>
      </div>
   )
}

export default Footer
